
let searchInput = document.getElementById("search-input");
let searchResults = document.getElementById("search-results");
let searchButton = document.getElementById("search_btn");
let api_url = location.origin + '/unit/' + 'live-search/';

async function fetchSearchResults(query) {
    searchResults.innerHTML = '';
    try {
        const response = await fetch(api_url + `?q=${query}`);
        return await response.json();
    } catch (error) {
        console.error('Error fetching data:', error);
        return [];
    }
}

async function updateSearchResults(query) {
    if (query.trim() === '') {
        searchResults.style.display = 'none'
        return;
    }

    const data = await fetchSearchResults(query);
    if (data.length === 0) {
        const resultItem = document.createElement('div');
        resultItem.classList.add('no-results');
        resultItem.textContent = 'No results found.';
        searchResults.appendChild(resultItem);
    } else {
        data.forEach(item => {
            const resultItem = document.createElement('div');
            resultItem.classList.add('result-item');
            resultItem.textContent = item.name;
            searchResults.appendChild(resultItem);
            resultItem.addEventListener('click', function () {
                searchInput.value = item.name;
                searchButton.click();
            })
        });
    }
    searchResults.style.display = 'block';
}

searchInput.addEventListener('input', () => {
    const query = searchInput.value.trim();
    updateSearchResults(query);
});

let resetButton = document.querySelector('button.reset');
let inputData = document.querySelector("#inputData");
let outputData = document.querySelector("#outputData");
let conversion_rate = inputData.getAttribute('data-rate');


//  Reset the value
resetButton.addEventListener('click', function () {
    inputData.value = '0';
    outputData.value = '0';
})

const handleDecimalPlaces = (provided_number, decimal_place_provided) => {
    let provided_number_str = provided_number.toString();

    if (provided_number_str.includes('.')) {
        let decimal_places = provided_number_str.split('.')[1].length;

        if (decimal_places > decimal_place_provided) {
            return provided_number.toFixed(decimal_place_provided).replace(/\.?0+$/, '');
        }
    }

    return provided_number;
}


//  Handle Input Change
inputData.addEventListener('input', () => {
    let input_value = inputData.value;
    let output_value = input_value * conversion_rate;

    if (output_value.toString().includes('.')) {
        output_value = output_value.toString().replace(/\.?0+$/, '');
        output_value = Number(output_value);
    }

    if (output_value >= 1000000000) {
        output_value = output_value.toExponential(6);
        const exponential_number_stripped = output_value.split("e");
        const before_exponential = exponential_number_stripped[0].replace(/\.?0+$/, '');

        output_value = `${before_exponential}e${exponential_number_stripped[1]}`;

    } else if (output_value >= 100000) {
        output_value = handleDecimalPlaces(output_value, 4);

    } else if (output_value < 100000 && output_value >= 1000) {
        output_value = handleDecimalPlaces(output_value, 6);

    } else if (output_value < 1000 && output_value >= 1) {
        output_value = handleDecimalPlaces(output_value, 9);

    } else if (output_value < 1 && output_value >= 0.001) {
        output_value = handleDecimalPlaces(output_value, 12);

    } else if (output_value < 0.001 && output_value >= 0.000000001) {
        output_value = handleDecimalPlaces(output_value, 20);

    } else if (output_value < 0.000000001 && output_value !== 0) {
        output_value = output_value.toExponential(6);
        const exponential_number_stripped = output_value.split("e");
        const before_exponential = exponential_number_stripped[0].replace(/\.?0+$/, '');

        output_value = `${before_exponential}e${exponential_number_stripped[1]}`;
    }

    outputData.value = output_value;
})

const lazyLoadForSmallerScreen = () => {
    const image = document.getElementById("converter_image");

    if (screen.width > 990) {
        image.removeAttribute("loading");
    } else if (screen.width <= 990) {
        image.setAttribute("loading", "lazy");
    }
}


lazyLoadForSmallerScreen();

// function copyFunction() {
//     let element_id = document.getElementById("copy_target");
//     element_id.setAttribute("contentEditable", true);
//     element_id.setAttribute("onfocus", "document.execCommand('selectAll', false, null)");
//     element_id.focus();
//     document.execCommand("copy");
//     element_id.removeAttribute("contentEditable");
// }


